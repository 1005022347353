export const routes =
    [
        {
            path: 'grupos/', name: 'dashboard_groups_list',
            meta: {
                module: "mod_grupos"
            },
            component: () => import('@/educap/views/dashboard/index_grupos')
        },
        {
            path: 'grupos/crear/', name: 'dashboard_groups_create',
            meta: {
                module: "mod_grupos",
                edit_mode: false
            },
            component: () => import('@/educap/views/dashboard/editor_grupo')
        },
        {
            path: 'grupos/editar/:id', name: 'dashboard_groups_edit',
            meta: {
                module: "mod_grupos",
                edit_mode: true
            },
            component: () => import('@/educap/views/dashboard/editor_grupo')
        },
        {
            path: 'evaluador/grupos/editar/:id', name: 'dashboard_groups_edit_evaluador',
            meta: {
                module: "mod_grupos",
                edit_mode: true
            },
            component: () => import('@/educap/views/dashboard/editor_grupo_evaluador')
        },
        {
            path: 'grupos/:id/', name: 'dashboard_groups_item',
            meta: {
                module: "mod_grupos"
            },
            component: () => import('@/educap/views/dashboard/item_grupo')
        }
    ]