import { axiosHelper } from '@/helpers'

const state = {
    app_mode: 0,
    is_loading: false,
    error_data: null,
    eval_id: 0,
    log_id: 0,
    question: {
        id: 0,
        total: 0,
        intentos: 0,
        data: null
    },
    input: {
        answered: false,
        is_correct: false
    },
    eval_data: null
}

const actions = {
    reset() {
        commit('idle');
    },
    start({ commit }, { eval_id }) {
        commit('idle');
        commit('clear_error');
        commit('mode_presentation');
        commit('emit_loading');
        
        return axiosHelper.get('edu/evaluaciones/' + eval_id + '/comenzar/')
            .then(response => {
                var log_id = response.data.logid;
                var eval_data = Object.assign({}, response.data);
                delete eval_data.logid;

                commit('begin_eval', { eval_id, log_id });
                commit('feed_data', eval_data);
                commit('stop_loading');
            })
            .catch(error => {
                commit('stop_loading');
                commit('emit_error', error);
            });
    },
    go_cuestionario({ commit, dispatch }) {
        commit('mode_cuestionario');
        dispatch('load_question');
    },
    load_question({ commit, state }) {
        if(!!state.is_loading) return;
        commit('clear_error');
        commit('emit_loading');
        return axiosHelper.post('edu/evaluaciones/' + state.eval_id + '/pregunta/', { qid: state.question.id, intentos: state.question.intentos })
            .then(response => {
                commit("feed_question", response.data);
                commit('stop_loading');
            })
            .catch(error => {
                commit('stop_loading');
                commit('emit_error', error);
            });
    },
    validate_answer({ commit, dispatch, state }, { answer }) {
        if(!!state.is_loading) return;

        var payload = {};
        payload['qid'] = state.question.id;
        payload['option'] = answer;
        payload['tryid'] = state.question.intentos;

        commit('clear_error');
        commit('emit_loading');
        return axiosHelper.post('edu/evaluaciones/' + state.eval_id + '/responder/', payload)
            .then(response => {
                commit('stop_loading');
                commit("mark_answer", response.data.is_correct);
                if(state.input.answered && !state.input.is_correct) {
                    commit("feed_intentos");
                    dispatch("load_question");
                }
            })
            .catch(error => {
                commit('stop_loading');
                commit('emit_error', error);
            });
    },
    trigger_success({ commit, dispatch, state }) {
        commit("clear_answer");
        if(state.question.id + 1 > state.question.total) {
            dispatch("end");
        } else {
            commit("next_question");
            dispatch("load_question");
        }
    },
    end({ commit, state }) {
        commit('clear_error');
        commit('emit_loading');
        var payload = { logid: state.log_id };
        axiosHelper.post('edu/evaluaciones/' + state.eval_id + '/finalizar/', payload)
            .then(() => {
                commit('stop_loading');
                commit('mode_finish');
            })
            .catch(error => {
                commit('stop_loading');
                commit('emit_error', error);
            });
    }
}

const mutations = {
    idle(state) {
        state.is_loading = false;
        state.error_data = null;
        state.log_id = 0;
        state.eval_id = 0;
        state.question.id = 0;
        state.question.total = 0;
        state.question.intentos = 0;
        state.question.data = null;
    },
    clear_error(state) {
        state.error_data = null;
    },
    emit_error(state, data) {
        state.error_data = data;
    },
    emit_loading(state) {
        state.is_loading = true;
    },
    stop_loading(state) {
        state.is_loading = false;
    },
    mode_presentation(state) {
        state.question.id = 0;
        state.app_mode = 0;
    },
    mode_cuestionario(state) {
        state.question.id = 1;
        state.app_mode = 1;
    },
    mode_finish(state) {
        state.question.id = state.question.total;
        state.app_mode = 2;
    },
    begin_eval(state, data) {
        state.eval_id = data.eval_id;
        state.log_id = data.log_id;
    },
    next_question(state) {
        state.question.intentos = 0;
        state.question.id ++;
    },
    clear_answer(state) {
        state.input.answered = false;
        state.input.is_correct = false;
    },
    mark_answer(state, is_correct) {
        state.input.answered = true;
        state.input.is_correct = is_correct;
    },
    feed_data(state, data) {
        var eval_data = Object.assign({}, data);
        delete eval_data.preguntas_count;

        state.question.total = data.preguntas_count;
        state.eval_data = eval_data;
    },
    feed_question(state, data) {
        state.question.data = data;
    },
    clear_intentos(state) {
        state.question.intentos = 0;
    },
    feed_intentos(state) {
        state.question.intentos++;
    }
}

export const evaluacion = {
    namespaced: true,
    state,
    actions,
    mutations
}