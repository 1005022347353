import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import VueMoment from 'vue-moment'
import DatetimePicker from 'vuetify-datetime-picker'

import App from './App.vue'
import router from './router'
import store from '@/store/cap'
import vuetify from '@/plugins/vuetify';
import filters from '@/helpers/filters';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


const moment = require('moment')
require('moment/locale/es')

Vue.use(VueMoment, { moment });
Vue.use(VueAxios, Axios);
Vue.use(VueMeta);
Vue.use(DatetimePicker);

filters.declare_all();

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  mounted() {
    this.$store.commit('auth/init_app', 'educap');
  }
}).$mount('#app')
