export default {
    valid_required,
    valid_username,
    valid_password,
    valid_email
}

export function valid_required(value) {
    return (value !== null && value !== undefined && value !== '') || "Este valor es obligatorio";
}

export function valid_fecha_required(value){
    return 
}
export function valid_username(value) {
    return /^([0-9a-zA-Z_]{5,})$/.test(value) || "Mínimo 5 carácteres, se aceptan números, guiones bajos, mayúsculas y minúsculas. Sin espacios.";
}

export function valid_password(value) {
    return !!value && value.length >= 8 || "Mínimo 8 carácteres.";
}

export function valid_email(value) {
    return /^([\w\W_.-0-9]+)@([a-zA-Z0-9]+)\.([a-zA-Z.]+)$/.test(value) || "Escriba un correo que sea valido.";
}