import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '@/assets/logo.jpg'; // logo para incluir en los reportes

function inicializarPdf(margin_=20, headerHeight_=5){

    const doc = new jsPDF();
    const margin = margin_;
    const fechaActual = new Date();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const addHeader = () => {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.addImage(logo, 'PNG', margin + 150, margin / 2, 20, 10); // Ajusta la posición según el margen
        }
    };

    const addFooter = () => {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            const text = `${fechaActual} Página ${i} de ${pageCount}`;
            const textWidth = doc.getTextWidth(text);
            doc.text(text, pageWidth - margin - textWidth, pageHeight - margin / 2);
        }
    };
    addHeader();
    addFooter();
    return doc;
};

export const pdfUtils = {
    inicializarPdf
};