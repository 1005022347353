<template>
  <v-app class="app">
      <router-view :key="$route.fullPath"></router-view>
  </v-app>
</template>

<style>
.app {
  overflow: hidden !important;
  background: linear-gradient(0deg, #f1f1f1 0%, #e0e0e0 100%) !important;
  /*background: #F1F1F1 !important;*/
}
</style>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Página sin titulo',
    titleTemplate: '%s - GMACAP',
    htmlAttrs: {
      lang: 'es'
    }
  },
  created() {
    this.axios.interceptors.request.use(
      (config) => {
      this.$store.commit('app/set_loading', true);
      return config;
    }, (error) => {
      this.$store.commit('app/set_loading', false);
      this.$store.commit('app/error', error);
      return Promise.reject(error);
    });

    this.axios.interceptors.response.use(
      (response) => {
      this.$store.commit('app/set_loading', false);
      return response;
    }, (error) => {
      this.$store.commit('app/set_loading', false);
      this.$store.commit('app/error', error);
      return Promise.reject(error);
    });
  }
};
</script>
