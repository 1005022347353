export const routes = 
[
    {
        path: 'evaluaciones/evaluar/:id/', name: 'dashboard_eval_run',
        meta: {
            module: "mod_capacitacion"
        },
        component: () => import('@/educap/views/dashboard/run_evaluacion')
    },
    {
        path: 'evaluaciones/editar/:id/', name: 'dashboard_eval_edit',
        meta: {
            module: "mod_capacitacion",
            edit_mode: true
        },
        component: () => import('@/educap/views/dashboard/editor_evaluacion')
    }
]