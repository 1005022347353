export const routes =
    [
        {
            path: 'estadisticas/', name: 'dashboard_stats',
            meta: {
                module: "mod_estadistica"
            },
            component: () => import('@/educap/views/dashboard/index_estadisticas')
        },
        {
            path: 'material/', name: 'dashboard_material',
            component: () => import('@/educap/views/dashboard/index_material')
        },
        {
            path: 'certificados/', name: 'dashboard_cert_list',
            component: () => import('@/educap/views/dashboard/index_certificados')
        },
        {
            path: 'reportes/', name: 'dashboard_reportes',
            component: () => import('@/educap/views/dashboard/index_reportes')
        }
    ]