<template>
    <div>
        <v-app-bar color="white" app flat>
            <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon @click="show_sidebar">
                <v-icon>menu</v-icon>
            </v-btn>
            <v-icon large color="secondary" v-if="!!title.icon">{{ title.icon }}</v-icon>
            <h1 class="title font-weight-regular pa-2 window-title">{{ title.text }}</h1>
        </v-app-bar>
        <Sidebar ref="sidebar" :data="menu" title="GMACAP" color="secondary">
            <template v-slot:user-sub="{ user, type }">
                <span class="d-block pb-1 font-weight-bold">{{ type }}</span>
                <span class="d-block pb-1">{{ user.empresa ? user.empresa.nombre : "Sin info." }}</span>
            </template>
        </Sidebar>
        <v-main class="ma-4">
            <router-view @title="set_title" :key="reload_count"></router-view>
        </v-main>
        <v-overlay fixed color="white" v-show="is_loading">
            <v-progress-circular indeterminate size="128" width="10" color="primary" />
        </v-overlay>
    </div>
</template>

<script>
import Sidebar from '@/shared/components/Sidebar'
import { mapState, mapActions } from 'vuex'
import { axiosHelper, user_isin } from '@/helpers'
import _menu from '@/assets/menu_cap.json'

export default {
    metaInfo() {
        return {
            title: this.title.text
        }
    },
    components: {
        Sidebar
    },
    data: () => ({
        title: {
            icon: "",
            text: ""
        },
        menu: _menu
    }),
    computed: {
        ...mapState('app', ['is_loading', 'error', 'module_access']),
        ...mapState('auth', ['user', 'logged', 'reload_count', 'message']),
    },
    methods: {  
        isin(dest_groups) {
            return user_isin(this.user.groups, dest_groups);
        },
        ...mapActions('auth', ['login_verify', 'login_refresh', 'reload', 'logout']),
        reload_dashboard() {
            this.reload();
        },
        set_title(text, icon) {
            this.title.text = text;
            this.title.icon = icon;
        },
        get_permission(mod_code, only_super=false) {
            if (this.user.groups.includes('supers')) return true;
            if (only_super || !this.module_access) return false;

            let access = this.module_access;
            return access[mod_code] || false;
        },
        show_sidebar() {
            this.$refs.sidebar.show();
        }
    },
    created() {
        this.login_verify();
    },
    watch: {
        logged: function(_new, _old) {
            if(_new == _old) return;

            if(!_new) {
                this.logout();
                this.$router.push({ name: 'login' });
            }
        }
    }
}
</script>