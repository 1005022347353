<template>
    <div class="primary">
        <v-img src="@/assets/cap_login.jpg" height="100vh">
            <v-container fluid fill-height>
                <v-flex style="max-width: 500px" class="mx-auto">
                    <LoginCard v-model="crd" :dev="dev" :busy="busy" @do-login="grantAccess" title="Capacitaciones" codename="GMACAP" />
                </v-flex>
                <!-- Dialogo de Error -->
                <Message ref="dialog" />
            </v-container>
        </v-img>
    </div>
</template>

<script>
import Message from '@/shared/components/Message'
import WindowCard from '@/shared/components/WindowCard'
import LoginCard from '@/shared/components/LoginCard'
import { mapState, mapActions } from 'vuex'
import { messages } from '@/helpers'

export default {
    metaInfo: {
        title: 'Iniciar Sesión'
    },
    components: {
        Message,
        WindowCard,
        LoginCard
    },
    data: () => ({
        crd: {
            username: '',
            password: ''
        },
        version: process.env.VUE_APP_VERSION
    }),
    computed: {
        ... mapState('auth', ['busy', 'message', 'logged']),
        dev() {
            return process.env.NODE_ENV == 'development';
        }
    },
    methods: {
        ... mapActions('auth', ['login']),
        grantAccess(e) {
            
            if(this.crd.username.length < 1) {
                this.$refs.dialog.show(messages.titles['error_login'], messages.texts['error_login_blank_1'], 'error');
                return;
            }

            if(this.crd.password.length < 1) {
                this.$refs.dialog.show(messages.titles['error_login'], messages.texts['error_login_blank_2'], 'error');
                return;
            }

            this.login(this.crd);
        }
    },
    watch: {
        message: function(_new, _old) {
            if(!_new) return;

            let text = messages.texts['error_desconocido']; 

            if(!!_new) {
                if(_new.status == 401) {
                    text = _new.data.detail || messages.texts['error_login_common'];
                }
            }

            this.$refs.dialog.show(messages.titles['error_login'], text, "error");
        },
        logged: function(_new, _old) {
            if(_new == _old) return;

            if(_new) {
                this.$router.push({ name: 'dashboard_index' });
            }
        }
    }
}
</script>