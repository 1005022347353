export const routes = 
[
    {
        path: 'cursos', name: 'dashboard_cap_list',
        meta: { 
            module: "mod_capacitacion"
        },
        component: () => import('@/educap/views/dashboard/index_curso')
    },
    {
        path: 'cursos/crear/', name: 'dashboard_cap_create',
        meta: { 
            edit_mode: false,
            module: "mod_capacitacion"
        },
        component: () => import('@/educap/views/dashboard/editor_curso')
    },
    {
        path: 'cursos/editar/:id/', name: 'dashboard_cap_edit',
        meta: { 
            edit_mode: true,
            module: "mod_capacitacion"
        },
        component: () => import('@/educap/views/dashboard/editor_curso')
    },
    {
        path: 'cursos/neweval/:id/', name: 'dashboard_eval_create',
        meta: {
            module: "mod_capacitacion",
            edit_mode: false
        },
        component: () => import('@/educap/views/dashboard/editor_evaluacion')
    },
    {
        path: 'cursos/:id/', name: 'dashboard_cap_item',
        meta: { 
            edit_mode: false,
            module: "mod_capacitacion"
        },
        component: () => import('@/educap/views/dashboard/item_curso')
    }
]