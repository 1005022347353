<template>
    <v-navigation-drawer v-model="visible" app :dark="dark" :class="bgClass"
    :fixed="$vuetify.breakpoint.mdAndDown"
    :permanent="!$vuetify.breakpoint.mdAndDown"
    :temporary="$vuetify.breakpoint.mdAndDown">
    
    <v-card flat tile :class="logo">
        <v-img src="@/assets/logo.jpg" contain></v-img>
    </v-card>
    <v-divider />
    <!-- menu de usuario -->
    <v-list-group no-action color="accent">
        <template slot="activator">
            <v-list-item-content>
                <v-list-item-title>
                    <p class="title font-weight-regular ma-0" :title="perfil?.first_name + ' ' + perfil?.last_name">{{ perfil?.first_name + ' ' + perfil?.last_name }}</p>
                </v-list-item-title>
                    <v-list-item-subtitle :title="get_type(user.groups)">
                        <slot name="user-sub" :user="perfil" :type="get_type(user.groups)">
                            {{ get_type(user.groups) }}
                        </slot>
                    </v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <v-divider />
        <v-list class="pa-0 transparent">
            <template v-for="(item, i) in user_menu">
                <v-list-item active-class="button" v-if="user_includes(item.users)" :color="color" :key="i" :to="{ name: item.route }" exact @click.stop="closeDrawer">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2">{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-list-group>
    <v-divider />
    <!-- menu general -->
    <v-list class="py-0 transparent">
        <v-list-item-group no-action>
            <template v-for="(m_item, i) in main_menu">
            <template v-if="!m_item.subitems">
                <v-list-item :key="i" v-if="user_includes(m_item.users)" :active-class="dark ? 'button' : null" :to="m_item.route ? { name: m_item.route } : null" :href="m_item.link" :target="m_item.target" exact @click.stop="closeDrawer">
                <v-list-item-action>
                    <v-icon>{{ m_item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">{{ m_item.text }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </template>
            <template v-else>
                <v-list-group no-action color="accent" :key="i" v-if="user_includes(m_item.users)" :active-class="dark ? 'button' : null" :prepend-icon="m_item.icon" @click.stop="closeDrawer">
                <template slot="activator">
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 font-weight-regular">{{ m_item.text }}</v-list-item-title>
                    </v-list-item-content>
                </template>
                <!-- submenu -->
                <v-list class="pa-0" color="rgba(0,0,0,0.25)">
                    <v-list-item color="accent" :active-class="dark ? 'button' : null" v-for="(subitem, i) in m_item.subitems" :key="i" :to="{ name: subitem.route }" @click.stop="closeDrawer">
                    <v-list-item-action>
                        <v-icon>{{ subitem.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2 font-weight-regular">{{ subitem.text }}</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
                </v-list-group>
            </template>
            </template>
        </v-list-item-group>
    </v-list>
    </v-navigation-drawer>
</template>

<style scoped>
.logo {
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 100%);
    text-shadow: 0px -1px rgba(0,0,0,0.125), 0px 1px rgba(255,255,255,0.25);
}

.button {
    color: white !important;
}

.button:before {
    background-color: rgb(27, 34, 44, 1) !important;
    box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,0.5);
}
.button:after {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    background-color: var(--v-primary-base) !important;
}
</style>

<script>
export default {
    props: ['title', 'bg-class', 'color', 'data', 'dark'],
    data: () => ({
        logo:null,
        visible: false,
    }),
    computed: {
        dev() {
            return process.env.NODE_ENV == 'development';
        },
        user() {
            return this.$store.state.auth.user;
        },
        perfil() {
            return this.user.perfil;
        },
        user_menu() {
            return this.data.user || [];
        },
        main_menu() {
            return this.data.menu || [];
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        get_type(groups) {
            let texts = []
            if(groups.includes('supers'))
                texts.push('Superusuario')
            else if(groups.includes('cap_evaluador'))
                texts.push('Evaluador')
            else if(groups.includes('cap_participante'))
                texts.push('Participante')
            if(groups.includes('administradores'))
                texts.push('Admin. GMA')
            if(groups.includes('empleados'))
                texts.push('Empleado GMA')

            if(texts.length > 0) {
                return texts.join(', ');
            }

            return "Desconocido"
        },
        user_includes(user_type) {
            if(user_type === 'all')
                return true;

            if(!user_type)
                return false;
            
            return user_type.some(tipo => this.user.groups.includes(tipo));
        },
        
        closeDrawer() {
            // Cierra el drawer solo si está en pantallas pequeñas

            
            //

            //

            // 
            if (this.$vuetify.breakpoint.mdAndDown) {
                this.visible = false;
            }
        }
    }
}
</script>