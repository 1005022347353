export const routes =
    [
        {
            path: 'cursos-externos/', name: 'dashboard_cursos_externos',
            component: () => import('@/educap/views/dashboard/index_cursos_externos')
        },
        {
            path: 'cursos_externos/importar', name: 'importar_cursos_externos',
            meta: {
                edit_mode: false
            },
            component: () => import('@/educap/views/dashboard/carga_cursos_externos')
        }
    ]