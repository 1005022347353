export const routes =
    [
        {
            path: 'usuarios', name: 'dashboard_usuarios',
            meta: {
                module: "mod_users",
                groups: ['supers']
            },
            component: () => import('@/educap/views/dashboard/index_usuarios.vue')
        },
        {
            path: 'usuarios/crear', name: 'dashboard_usuarios_create',
            meta: {
                module: "mod_users",
                editMode: false,
                groups: ['supers']
            },
            component: () => import('@/educap/views/dashboard/editor_usuarios.vue')
        },
        {
            path: 'usuarios/editar/:id', name: 'dashboard_usuarios_edit',
            meta: {
                module: "mod_users",
                editMode: true,
                groups: ['supers']
            },
            component: () => import('@/educap/views/dashboard/editor_usuarios.vue')
        },
        {
            path: 'usuarios/activo/editar', name: 'dashboard_usuarios_edit_self',
            meta: {
                module: "mod_users",
                editMode: true,
                selfMode: true,
                groups: ['supers']
            },
            component: () => import('@/educap/views/dashboard/editor_usuarios.vue')
        },
        {
            path: 'editar-cursos-externos/:idParticipante', name: 'editar-cursos-externos',
            meta: {
                module: "mod_users",
                editMode: true,
                selfMode: true,
                groups: ['supers']
            },
            component: () => import('@/educap/views/dashboard/editar-cursos-externos.vue'),
            props: true  // Asegúrate de pasar 'id' como prop
        }
    ]