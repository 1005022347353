<template>
<div>
    <v-card tile flat elevation="20" class="log">
        <v-flex class="header align-end">
            <v-row :class="$vuetify.breakpoint.smAndUp ? 'mx-8 pt-4' : 'ma-0'" justify="center">
                <v-col class="pa-4 flex-grow-1 flex-shrink-0">
                    <span class="d-block title"><v-icon>double_arrow</v-icon>{{ title }}</span>
                    <span class="d-block subtitle">{{ codename }}</span>
                </v-col>
            </v-row>
            <v-row id="anim" no-gutters class="ma-0">
                <v-col cols="3" class="c1"></v-col>
                <v-col cols="3" class="c2"></v-col>
                <v-col cols="3" class="c3"></v-col>
                <v-col cols="3" class="c4"></v-col>
            </v-row>
        </v-flex>
        <v-divider />
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? 'px-12 py-6' : ''">
            <form ref="form" id="login_form">
                <v-row>
                    <v-col cols="12">
                        <v-label>{{cap ? 'R.U.T o nombre de usuario' : 'Nombre de usuario' }}</v-label>
                        <v-text-field solo outlined dense flat hide-details v-model="value.username" :placeholder="cap ? 'Nombre de usuario o 11111111-k' : 'Nombre de usuario'" prepend-inner-icon="account_circle" :loading="busy" :disabled="busy"/>
                    </v-col>
                    <v-col cols="12">
                        <v-label>Contraseña</v-label>
                        <v-text-field solo outlined dense flat hide-details v-model="value.password" type="password" prepend-inner-icon="vpn_key" :loading="busy" :disabled="busy" />
                    </v-col>
                </v-row>
            </form>
        </v-card-text>
        <v-divider />
        <v-card-actions :class="$vuetify.breakpoint.smAndUp ? 'px-12 py-6' : ''">
            <v-btn depressed color="primary" block large form="login_form" type="submit" @click="trigger_login" :disabled="busy" :loading="busy">
                <span class="subtitle-1">Iniciar sesión</span>
                <v-icon right>chevron_right</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
    <v-flex text-center class="py-2" v-if="version">
        <v-chip dark color="rgba(0,0,0,0.5)">{{ version }}</v-chip>
    </v-flex>
</div>
</template>

<style scoped>

.header > #anim .col {
    height: 10px;
    display: block;
    background-color: white;
    opacity: 0;
}

@keyframes opa {
  from { opacity: 0; }
  to { opacity: 1; }
}
.header .icon {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-delay: 1000ms;
    animation-name: opa;
    animation-duration: 1s;
}

.header #anim .c1 {
    background-color: rgb(0, 122, 148);
    animation-fill-mode: forwards;
    animation-delay: 800ms;
    animation-name: opa;
    animation-duration: 1s;
}

.header #anim .c2 {
    background-color: rgb(98, 205, 202);
    animation-fill-mode: forwards;
    animation-delay: 650ms;
    animation-name: opa;
    animation-duration: 1s;
}

.header #anim .c3 {
    background-color: rgb(212, 32, 41);
    animation-fill-mode: forwards;
    animation-delay: 400ms;
    animation-name: opa;
    animation-duration: 1s;
}

.header #anim .c4 {
    background-color: rgb(237, 171, 0);
    animation-fill-mode: forwards;
    animation-delay: 250ms;
    animation-name: opa;
    animation-duration: 1s;
}

</style>

<script>
export default {
    props: ['title', 'codename', 'value', 'dev', 'busy', 'version', 'cap'],
    methods: {
        trigger_login(e) {
            e.preventDefault();
            this.$emit('do-login');
        }
    }
}
</script>